@import "../../SCSS/variables.scss";
@import "../../SCSS/theme/fonts.scss";

.swal2-container.custom-container {

    .custom-popup {
        overflow: hidden;
        background-color: inherit;
        padding: 0;

        .custom-header {
            background-color: inherit;
            height: $header-height;
            
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);

            .swal2-title {
                @include text-extra-large;
                color: $textColorWhite;
                margin: 0;
            }
        }

        .custom-content {
            padding: $side-spacing;
            min-height: 100px;
            display: flex;
            align-items: center;

            color: $textColorWhite;
            @include font-roboto-regular;
            @include text-small;
        }

        .custom-actions {
            flex-wrap: nowrap;
            justify-content: space-around;
            padding: $side-spacing;
            margin: 0;
            width: auto;

            button {
                box-shadow: none;
                margin: 0;
                padding: 0;

                &:nth-child(2) {
                    margin-left: 15px;
                }
            }

            .custom-cancel-button {
                @include font-montserrat-regular;
                font-size: 1em;
                padding: 12px 0;
                color: $textColorWhite;
                border-radius: 0;
                text-decoration: underline;
                background: transparent;
                opacity: 0.6;
            }

            .custom-confirm-button {
                @include font-montserrat-semibold;
                color: $textColorBlack;
                border-radius: 0;
                padding: 12px 19px;
                background-color: $secondaryColor;
            }

            &.full-width-button {
                justify-content: center;

                button {
                    &:nth-child(2) {
                        margin-left: 0;
                    }
                }
            }
        }

    }
}
