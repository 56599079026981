@import "variables.scss";
@import "../SCSS/theme/fonts.scss";

html, body {
    height: 100%;
    margin: 0;
}

h1 {
    @include font-montserrat-bold;
    @include  text-extra-large;
}

h2 {
    @include font-montserrat-bold;
    @include  text-large;
}

h3 {
    @include font-montserrat-semibold;
    font-size: 18px;
}

h4 {
    @include font-montserrat-regular;
    font-size: 1em;
}

h5 {
    @include font-montserrat-bold;
    font-size: 0.8em;
}

p {
    @include font-roboto-regular;
    @include text-medium;
}

body{
    @include font-montserrat-regular;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    color: $textColorWhite;
}

#root {
    background-color: rgba(0, 0, 0, 0.4);
}

#root, .globalform-wrapper {
    display: flex;
    flex-flow: column;
    height: 100%;
}

input, span, textarea, select {
    @include font-roboto-regular;
    @include text-standard;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.link{
    color: $secondaryColor;
}

%button-styling {
    @include font-roboto-regular;
    @include text-medium;
    border: none;
    height: $buttonHeight;
    display: flex;
    align-items: center;
    outline: none;
    transition: 0.1s;
}

%button-styling:active {
    opacity: 0.8;
}

%button-styling-navigation {
    margin: 0;
    padding: 0;
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    width: $buttonWidth;

    svg{
        display: inline-block;
        vertical-align: middle;
    }
}

.explanation, .confirmation, .thankyou, .no-questions-available{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;

    .text-container{
        margin-bottom: 10%;

        p{
            overflow: auto;
            max-height: 300px;
            line-height: 20px;
        }
    }

    .invitation-action{
        display: flex;
        flex-direction: column;
        width: 85%;
        
        .accept-action{
            @extend %button-styling;
            width: 100%;
            height: 42px;
            display: inline-block;
            background-color: $secondaryColor;
            margin-bottom: 7px;
        }

        .decline-action{
            @extend %button-styling;
            font-weight: 400;
            width: 100%;
            height: 42px;
            display: inline-block;
            background-color:transparent;
            color: white;
            text-decoration:underline;
            opacity: 0.6;
        }
    }
}

.header {
    flex: 0 1 $header-height;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    h1 {
        @include text-large;
    }    

    .header-action-right{
        position: absolute;
        right: 0;
        padding-right: $side-spacing;
    }
}


form {
    display: flex;
    flex-flow: column;
    flex: auto;

    .formError{
        @include text-smaller;
        color: #D33F49;
    }

    .formError::before {
        content: "⚠ ";
    }
    .question-container{
        flex: auto;
        padding-left: $side-spacing;
        padding-right: $side-spacing;

        .input-container {
            display: flex;
            flex-direction: column;

            input, label{
                color: $textColorBlack;
                background-color: #ffffff;
                outline: 0;
            }

            label{
                padding-left: $input-padding;
                padding-right: $input-padding;
            }

            input[type=date], .question-text, .question-select, .question-number{
                width: 100%;
                box-sizing: border-box;
                height: $input-height;
                padding-left: $input-padding;
                padding-right: $input-padding;
                border: 0;
            }

            .question-textarea{
                background-color: white;
                padding: $input-padding;
                height: 216px;
                border:0px;
                outline:0px;
                resize: none;
            }

            input:disabled[type=text], textarea:disabled, input:disabled[type=number], input:disabled[type=date], select:disabled{
                opacity: 1;
                color: rgba(0,0,0,0.6);
            }

            .disabled-styling{
                span, input:disabled{
                    opacity: 0.6;
                }
            }

            //input:disabled, textarea:disabled, select:disabled, {
            //    input{
            //        opacity: 0.8;
            //    }
            //}



            // input type: checkbox or radio
            .question-label-options{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row-reverse;
                height: $input-height;
                margin-bottom: 10px;

                input[type=checkbox], input[type=radio] {
                    width: 24px;
                    height: 24px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                }
                input[type=checkbox]:checked, input[type=radio]:checked{
                    background-image: url(../Icons/check.svg);
                }

                input[type=checkbox]:checked+span, input[type=radio]:checked+span{
                    @include font-roboto-bold;
                }

                input[type=checkbox]:not(:checked), input[type=radio]:not(:checked) {
                    opacity: 0;
                }
            }

            .checkbox-hint {
                @include text-smaller;
                color: #C2C2C2;
                font-style: italic;
                margin: 0em 0em 1.5em 0em;
            }

            .date-container{
                position: relative;
            }

            .date-button{
                position: absolute;
                top: 13px;
                right: 13px;
                width: 24px;
                height: 24px;
                background: url(../Icons/datepicker-black.svg) center no-repeat;
                color: rgba(0, 0, 0, 0);
                opacity: 0.4;
                pointer-events: none;
            }

            input[type="date"]:not(.has-value):before{
                content: attr(placeholder);
                width: 100%;
                opacity: 0.6;
            }

            input[type="date"]:disabled::before {
                width: auto;
            }

            input[type="date"]:focus::before, input[type="date"]:valid::before {
                display: none;
            }

            input::-webkit-outer-spin-button,
            input[type="date"]::-webkit-inner-spin-button,
            input[type="date"]::-webkit-calendar-picker-indicator,
            input::-webkit-clear-button{
                display: none;
                -webkit-appearance: none;
            }
        }

        .select-container{
            @extend .input-container;
            background-color: #ffffff;
            select {
                background-color: #ffffff;
                background-image: url(../Icons/dropdown-black.svg);
                background-repeat: no-repeat;
                background-position: calc(100% - 16px) center;
                border:0px;
                outline:0px;
            }
        }
    }

    .stepCounter{
        p{
            @include text-small;
        }

        text-align: center;
        width: 100%;
        bottom: 0;
        color: #C2C2C2;
    }

    .formAction {
        flex: 0 1 $footer-height;
        width: 100%;
        background:rgba(255, 255, 255, 0.15);

        .nextButton, .backButton, .submitButton{
            @extend %button-styling;
            @extend %button-styling-navigation;
        }

        .nextButton {
            background-color: $secondaryColor;
            right: 0;
        }
        
        .backButton {
            background-color: Transparent;
            left: 0;
        }      
        
        .submitButton {
            background-color: $secondaryColor;
            width: auto;
            right: 0;
            padding-left: $side-spacing;
        }
    }
}

@media (min-width: 1025px) {
    html {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
    }

    body {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        position: relative;
        width: 414px;
        height: 736px;
    }
}



  
