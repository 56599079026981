@mixin font-montserrat-regular {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

@mixin font-montserrat-semibold {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

@mixin font-montserrat-bold {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}

@mixin font-roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

@mixin font-roboto-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
}

@mixin text-smaller {
    font-size: 14px;
}

@mixin text-small {
    font-size: 15px;
}

@mixin text-medium {
    font-size: 16px
}

@mixin text-standard {
    font-size: 17px
}

@mixin text-large {
    font-size: 20px
}

@mixin text-extra-large {
    font-size: 23px
}

