@import "variables.scss";
@import "../SCSS/theme/fonts.scss";

input[type=range] {
	background: none !important;
	-webkit-appearance: none;
	margin: 15px 0;
	width: 100%;
}

input[type=range]:focus {
	outline: none;
}

input[type=range]::-moz-range-progress, input[type="range"]::-moz-range-track {
	background: $secondaryColor;
	height: 12px;
	border-radius: 6px;
}
input[type=range]::-moz-range-thumb{
	height: 25px;
	width: 25px;
	border-radius: 50%;
	box-shadow: 0 0 4px 0 rgba(0,0,0, 1);
	background: #fff;
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -8px;
}

input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 12px;
	background: $secondaryColor;
	border-radius: 6px;
}
input[type=range]::-webkit-slider-thumb{
	height: 25px;
	width: 25px;
	border-radius: 50%;
	box-shadow: 0 0 4px 0 rgba(0,0,0, 1);
	background: #fff;
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -8px;
}
.range-wrap{
	margin-block-start: 1.33em;
	position: relative;
}
.range-value{
	position: absolute;
	top: -50%;
}
.range-value span{
	@include font-roboto-regular;
	@include text-medium;
	width: 30px;
	height: 24px;
	line-height: 24px;
	text-align: center;
	color: #fff;
	display: block;
	left: 50%;
	transform: translate(-50%, 0);
}

.disabled-styling{
	.range-wrap{
		opacity: 0.8;
	}
}