@import "variables.scss";

.image-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    input[type=checkbox], input[type=radio],
    {
        border-radius: 0;
        box-shadow: inset 0 0 0 4px transparent;
        width: 100%;
        display: inline-block;
        padding-top: 100%;
        margin: 0;
    }

    input[type=checkbox]:checked, input[type=radio]:checked,
    {
        box-shadow: inset 0 0 0 4px $secondaryColor;
    }
}

.disabled-styling{
    .image-container{
        opacity: 0.6;
    }
}
