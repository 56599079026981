$primaryColorDark: #070031;
$primaryColorLight: #29234d;
$secondaryColor: #fcffd3;

$textColorWhite: #ffffff;
$textColorBlack: #333333;

$header-height: 56px;

$side-spacing: 20px;

$input-height: 50px;
$input-font-size: 17px;
$input-padding: 13px;

$buttonWidth: 56px;
$buttonHeight: 56px;

$footer-height: 56px;

// Export to access the variables in React Components
:export {
    primaryColorDark: $primaryColorDark;
    primaryColorLight: $primaryColorLight;
    secondaryColor: $secondaryColor;
    textColorWhite: $textColorWhite;
    textColorBlack: $textColorBlack;
}